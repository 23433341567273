.container {
    width: 9.5in;
    height: 4.5in;
    padding: 0.2in;
    border: 1px solid #ccc;
    background-color: white;
}

.styled-div {
    padding: 0.3in;
    border: 0.03in solid black;
    height: 80%;
}

.info-row {
    display: flex;
}

.info-label, .info-value {
    font-weight: bold;
    font-size: 15.5px;
    text-align: left;
}

.qr-code {
    width: 110px;
    height: 110px;
}

.logo {
    width: 110px;
    height: 110px;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.title {
    font-weight: bold;
    font-size: 18px;
    border: 2px solid black;
    display: inline-block;
    padding: 5px;
}

.company-name {
    font-weight: bold;
    font-size: 24px;
}

.license-number {
    font-weight: bold;
    font-size: 16px;
}

.info-section {
    margin-top: 16px;
    text-align: center;
} 